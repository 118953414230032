<!-- 企业员工 -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <h3 class="text-center p-50">{{hasEdit==false?'绑定员工':'编辑权限'}}</h3>
                <div>
                    <el-form :model="enterApplyForm" :rules="enterApplyFormRules" ref="enterApplyForm" label-width="120px">
                        <el-form-item label="用户搜索" required>
                            <div v-if="selectObj">
                                <div class="df box">
                                    <img :src="$oucy.ossUrl + selectObj.ltUserDetailFull.detailAvatar" class="detailAvatar">
                                    <div class="text">
                                        <div class="f14">{{selectObj.ltUserDetailFull.detailNickname}}</div>
                                        <div class="f12">{{selectObj.userPhoneNumber}}</div>
                                    </div>
                                    <i class="el-icon-error delbox" @click="delSelctObj" v-if="!hasEdit"></i>
                                </div>
                            </div>
                            <el-input v-else placeholder="搜索选择后自动录入信息" @focus="openDialogFormVisible"></el-input>
                        </el-form-item>

                        <el-form-item label="是否管理员">
                            <el-switch v-model="enterApplyForm.isAdmin">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="是否客服">
                            <el-switch v-model="enterApplyForm.isCustomer">
                            </el-switch>
                        </el-form-item>
                        <el-form-item label="权限分配" v-if="enterApplyForm.isAdmin==false">
                            <el-checkbox v-model="v.checked" v-for="(v,i) of itEnterpriseMenuTree" :key="i">{{v.enterpriseMenuName}}</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submit" type="primary" v-if="hasEdit">保存</el-button>
                            <el-button @click="submit" type="primary" v-else>绑定</el-button>
                            <el-button @click="$oucy.back()">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
        <selectUserDialog ref="selectUserDialog" @callBack="callBack" />
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { ltIdentity, cardCategory, cardEmployee, ltEnterpriseMenu, enterpriseinfo } from "@/service"
export default {
    name: "EnterpriseApply1111",
    components: {
        addr,
        uploadImage
    },
    data() {
        return {
            enterApplyForm: {
                userAuthId: null,
                "isAdmin": true,
                "isCustomer": !true,
                "enterpriseMenuIds": null,

            },

            enterApplyFormRules: {
                userAuthId: [
                    { required: true, message: '请选择用户', trigger: 'blur' },
                ],

            },

            hasEdit: null,
            selectObj: null,
            itEnterpriseMenuTree: [],
            itEnterpriseMenuTreeId: [],
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.id = this.$route.query.id
        if (this.id) {
            this.hasEdit = true
            // 查企业员工详情
            this.queryEmployeeMenuAuth()
        } else {
            this.isShowCityCode = true
            this.hasEdit = false
            this.getLtEnterpriseMenuTree()
        }

    },
    methods: {

        // 查询企业菜单树
        getLtEnterpriseMenuTree() {
            ltEnterpriseMenu.getLtEnterpriseMenuTree({}).then(res => {
                if (res) {
                    for (let v of res) {
                        v.checked = (this.enterApplyForm.isAdmin || this.itEnterpriseMenuTreeId.includes(v.id))?true:false
                    }
                }
                this.itEnterpriseMenuTree = res || []
            })
        },
        // 查企详情
        queryEmployeeMenuAuth() {
            enterpriseinfo.queryEmployeeMenuAuth({ id: this.id, type: false }).then(res => {
                // 有数据
                if (res) {
                    this.enterApplyForm = res
                    // 有绑定员工的 回填
                    this.selectObj = res
                    this.enterApplyForm.userAuthId=res.ltUserId
                    if (res.enterpriseMenus) {
                        for (let v of res.enterpriseMenus) {
                            this.itEnterpriseMenuTreeId.push(v.id)
                        }
                    }
                }
                this.getLtEnterpriseMenuTree()
            })
        },
        // 打开选择用户
        openDialogFormVisible() {
            this.$refs.selectUserDialog.openDialogFormVisible()
        },
        // 发布企业员工
        setEmployee() {
            enterpriseinfo.setEmployee(this.enterApplyForm).then(res => {
                this.$alert('添加成功')
                this.$oucy.back()
            })
        },
        // 修改企业员工
        setEmployeeMenuAuth() {
            enterpriseinfo.setEmployeeMenuAuth(this.enterApplyForm).then(res => {
                // 有数据
                this.$alert('修改成功')
                this.$oucy.back()

            })
        },
        getChecked() {
            let list = []
            for (let v of this.itEnterpriseMenuTree) {
                if (v.checked) {
                    list.push(v.id)
                }
            }
            return list
        },

        // 提交
        submit: function() {
            if (!this.enterApplyForm.userAuthId) {
                this.$alert('请选择要绑定的用户', '提示')
                return
            }
            const me = this;
            this.enterApplyForm.enterpriseId = this.enterpriseId
            if (this.enterApplyForm.isAdmin == false) {
                let enterpriseMenuIds = this.getChecked()
                // JSON.parse()
                this.enterApplyForm.enterpriseMenuIds = enterpriseMenuIds.length ? enterpriseMenuIds.join() : null
                // this.enterApplyForm.enterpriseMenuIds=enterpriseMenuIds.length?JSON.stringify(enterpriseMenuIds):null
            }

            me.$refs["enterApplyForm"].validate((valid) => {
                if (valid) {

                    if (me.hasEdit) {
                        this.setEmployeeMenuAuth()
                    } else {
                        this.setEmployee()
                    }
                }
            });
        },
        // 选择用户回调
        callBack(v) {
            this.enterApplyForm.userAuthId = v.id
            this.selectObj = v
        },
        // 删除选择用户
        delSelctObj() {
            this.enterApplyForm.userAuthId = null
            this.selectObj = null
        },

    }
}
</script>
<style scoped>
.apply-container {
    width: 500px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    line-height: normal !important;
    height: 200px;
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;
}

.detailAvatar {
    width: 42px;
    height: 42px;
}

.box {
    border: 1px #f1f1f1 solid;
    padding: 10px;
    position: relative;
}

.text {
    line-height: 20px;
    color: #333;
    padding-left: 10px;
}

.delbox {
    font-size: 18px;
    color: rgba(0, 0, 0, .5);
    padding: 4px;
    position: absolute;
    right: -10px;
    top: -10px;
}
</style>